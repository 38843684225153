import { AtomOptions } from 'recoil';

export default function localStorageEffect<T>(key: string) {
  const localKey = `birdcall.${key}`;
  const foo: AtomOptions<T>['effects_UNSTABLE'] = [
    // On load, pull value from localStorage ( if present )
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        const localValue = localStorage.getItem(localKey);
        if (localValue !== null) {
          setSelf(JSON.parse(localStorage.getItem(localKey) ?? 'null'));
        }
      }
    },
    // Anytime recoil value changes, save to local storage
    ({ onSet }) => {
      onSet(recoilValue => localStorage.setItem(localKey, JSON.stringify(recoilValue)));
    },
  ];
  return foo;
}
